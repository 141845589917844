import projectConfig from "@/config";

export default {
  computed: {
    getLogo() {
      if(this.$store.state.theme === "dark") {
        return projectConfig.darkLogo;
      } else {
        return projectConfig.logo;
      }
    },
    getFullLogo() {
      if(this.$store.state.theme === "dark") {
        return projectConfig.fullDarkLogo;
      } else {
        return projectConfig.fullLogo;
      }
    },
  },
};
