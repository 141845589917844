var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',{staticClass:"min-h-screen flex items-center"},[_c('a-card',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.hasChats)?_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('img',{staticClass:"mx-auto m-8 mb-5",staticStyle:{"max-width":"60%"},attrs:{"src":_vm.getFullLogo,"alt":"logo"}}),_c('select-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.form,
              'key': 'chatId',
              'prefix': 'choose_chat_',
              'options': _vm.getChatsOptions,
              'validation': 'required'
            }
          }}}),_c('div',{staticClass:"flex flex-row justify-end mt-base"},[_c('a-button',{attrs:{"icon":"arrow-right","type":"primary","disabled":invalid},on:{"click":_vm.goToChatBuyPage}},[_vm._v(" "+_vm._s(_vm.$t("choose_chat_button_next"))+" ")])],1)]}}],null,false,3454748628)}):_c('empty-data',{scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("choose_chat_no_chats"))+" "),_c('div',[_c('a-button',{attrs:{"icon":"plus","type":"primary"},on:{"click":_vm.goToAddChatPage}},[_vm._v(" "+_vm._s(_vm.$t('choose_chat_add_chat'))+" ")])],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }